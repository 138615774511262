import { create, StateCreator } from 'zustand'

const isUndefined = (value: unknown) => value === undefined

// Types
// States type
interface UserStoreStatesType {
  isFetched: boolean
  id: string | null
  lastName: string | null
  middleName: string | null
  firstName: string | null
  email: string | null
  phone: string | null
  district: string | null
  ward: string | null
  isAdmin: boolean
}

// Actions type
interface SetUserParamsType {
  id?: string
  lastName?: string
  middleName?: string
  firstName?: string
  email?: string
  phone?: string
  district?: string
  ward?: string
  isAdmin?: boolean
}

interface UserStoreActionsType {
  setIsFetched: (isFetched: boolean) => void
  setUser: (params: SetUserParamsType) => void
  removeUser: () => void
}

// Constants
const DEFAULT_USER_STORE_STATES: UserStoreStatesType = {
  isFetched: false,
  id: null,
  lastName: null,
  middleName: null,
  firstName: null,
  email: null,
  phone: null,
  district: null,
  ward: null,
  isAdmin: false
}

// Define store
const userStore: StateCreator<UserStoreStatesType & UserStoreActionsType> = (set) => {
  return {
    // States
    ...DEFAULT_USER_STORE_STATES,

    // Actions
    setIsFetched: (isFetched) =>
      set(() => ({
        isFetched
      })),
    setUser: ({ id, lastName, middleName, firstName, email, phone, district, ward, isAdmin }) => {
      // Set user data
      set((state) => ({
        id: isUndefined(id) ? state.id : id,
        lastName: isUndefined(lastName) ? state.lastName : lastName,
        middleName: isUndefined(middleName) ? state.middleName : middleName,
        firstName: isUndefined(firstName) ? state.firstName : firstName,
        email: isUndefined(email) ? state.email : email,
        phone: isUndefined(phone) ? state.phone : phone,
        district: isUndefined(district) ? state.district : district,
        ward: isUndefined(ward) ? state.ward : ward,
        isAdmin: isUndefined(isAdmin) ? state.isAdmin : isAdmin
      }))
    },
    removeUser: () => {
      // Reset user data
      set(() => ({
        ...DEFAULT_USER_STORE_STATES
      }))
    }
  }
}

const useUserStore = create(userStore)

export default useUserStore
