/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * SHTT
 * Coded by Meu TEAM
 * OpenAPI spec version: 1.0.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetUserExamParams,
  GetUserParams,
  Response,
  SubmitParticipantAnswers,
  UpdateUserInfo
} from '../models'
import { customClient } from '../mutator/custom-client';
import type { ErrorType, BodyType } from '../mutator/custom-client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get user by ID.
 */
export const getUserId = (
    id: string,
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/user/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserIdQueryKey = (id: string,) => {
    return [`/user/${id}`] as const;
    }

    
export const getGetUserIdInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserId>>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserId>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserId>>> = ({ signal }) => getUserId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserId>>>
export type GetUserIdInfiniteQueryError = ErrorType<unknown>

export const useGetUserIdInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserId>>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserId>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserIdInfiniteQueryOptions(id,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserId>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserId>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserId>>> = ({ signal }) => getUserId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserId>>>
export type GetUserIdQueryError = ErrorType<unknown>

export const useGetUserId = <TData = Awaited<ReturnType<typeof getUserId>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserId>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update user by ID.
 */
export const putUserId = (
    id: string,
    updateUserInfo: BodyType<UpdateUserInfo>,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/user/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserInfo
    },
      options);
    }
  


export const getPutUserIdMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserId>>, TError,{id: string;data: BodyType<UpdateUserInfo>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putUserId>>, TError,{id: string;data: BodyType<UpdateUserInfo>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserId>>, {id: string;data: BodyType<UpdateUserInfo>}> = (props) => {
          const {id,data} = props ?? {};

          return  putUserId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putUserId>>>
    export type PutUserIdMutationBody = BodyType<UpdateUserInfo>
    export type PutUserIdMutationError = ErrorType<unknown>

    export const usePutUserId = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserId>>, TError,{id: string;data: BodyType<UpdateUserInfo>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUserId>>,
        TError,
        {id: string;data: BodyType<UpdateUserInfo>},
        TContext
      > => {

      const mutationOptions = getPutUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete user by ID.
 */
export const putUserIdDelete = (
    id: string,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/user/${id}/delete`, method: 'PUT'
    },
      options);
    }
  


export const getPutUserIdDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserIdDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putUserIdDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserIdDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  putUserIdDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof putUserIdDelete>>>
    
    export type PutUserIdDeleteMutationError = ErrorType<unknown>

    export const usePutUserIdDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserIdDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUserIdDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPutUserIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Begin an exam
 */
export const putUserExamExamIdBegin = (
    examId: string,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/user/exam/${examId}/begin`, method: 'PUT'
    },
      options);
    }
  


export const getPutUserExamExamIdBeginMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdBegin>>, TError,{examId: string}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdBegin>>, TError,{examId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserExamExamIdBegin>>, {examId: string}> = (props) => {
          const {examId} = props ?? {};

          return  putUserExamExamIdBegin(examId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserExamExamIdBeginMutationResult = NonNullable<Awaited<ReturnType<typeof putUserExamExamIdBegin>>>
    
    export type PutUserExamExamIdBeginMutationError = ErrorType<unknown>

    export const usePutUserExamExamIdBegin = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdBegin>>, TError,{examId: string}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUserExamExamIdBegin>>,
        TError,
        {examId: string},
        TContext
      > => {

      const mutationOptions = getPutUserExamExamIdBeginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get exam details for participant
 */
export const getUserExamExamIdDetails = (
    examId: string,
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/user/exam/${examId}/details`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserExamExamIdDetailsQueryKey = (examId: string,) => {
    return [`/user/exam/${examId}/details`] as const;
    }

    
export const getGetUserExamExamIdDetailsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserExamExamIdDetails>>>, TError = ErrorType<unknown>>(examId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserExamExamIdDetailsQueryKey(examId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExamExamIdDetails>>> = ({ signal }) => getUserExamExamIdDetails(examId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(examId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserExamExamIdDetailsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExamExamIdDetails>>>
export type GetUserExamExamIdDetailsInfiniteQueryError = ErrorType<unknown>

export const useGetUserExamExamIdDetailsInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserExamExamIdDetails>>>, TError = ErrorType<unknown>>(
 examId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserExamExamIdDetailsInfiniteQueryOptions(examId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserExamExamIdDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError = ErrorType<unknown>>(examId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserExamExamIdDetailsQueryKey(examId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExamExamIdDetails>>> = ({ signal }) => getUserExamExamIdDetails(examId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(examId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserExamExamIdDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExamExamIdDetails>>>
export type GetUserExamExamIdDetailsQueryError = ErrorType<unknown>

export const useGetUserExamExamIdDetails = <TData = Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError = ErrorType<unknown>>(
 examId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdDetails>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserExamExamIdDetailsQueryOptions(examId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Register an exam
 */
export const putUserExamExamIdRegister = (
    examId: string,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/user/exam/${examId}/register`, method: 'PUT'
    },
      options);
    }
  


export const getPutUserExamExamIdRegisterMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdRegister>>, TError,{examId: string}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdRegister>>, TError,{examId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserExamExamIdRegister>>, {examId: string}> = (props) => {
          const {examId} = props ?? {};

          return  putUserExamExamIdRegister(examId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserExamExamIdRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof putUserExamExamIdRegister>>>
    
    export type PutUserExamExamIdRegisterMutationError = ErrorType<unknown>

    export const usePutUserExamExamIdRegister = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdRegister>>, TError,{examId: string}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUserExamExamIdRegister>>,
        TError,
        {examId: string},
        TContext
      > => {

      const mutationOptions = getPutUserExamExamIdRegisterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get exam result for participant
 */
export const getUserExamExamIdResult = (
    examId: string,
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/user/exam/${examId}/result`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserExamExamIdResultQueryKey = (examId: string,) => {
    return [`/user/exam/${examId}/result`] as const;
    }

    
export const getGetUserExamExamIdResultInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserExamExamIdResult>>>, TError = ErrorType<unknown>>(examId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserExamExamIdResultQueryKey(examId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExamExamIdResult>>> = ({ signal }) => getUserExamExamIdResult(examId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(examId), ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserExamExamIdResultInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExamExamIdResult>>>
export type GetUserExamExamIdResultInfiniteQueryError = ErrorType<unknown>

export const useGetUserExamExamIdResultInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserExamExamIdResult>>>, TError = ErrorType<unknown>>(
 examId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserExamExamIdResultInfiniteQueryOptions(examId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserExamExamIdResultQueryOptions = <TData = Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError = ErrorType<unknown>>(examId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserExamExamIdResultQueryKey(examId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExamExamIdResult>>> = ({ signal }) => getUserExamExamIdResult(examId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(examId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserExamExamIdResultQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExamExamIdResult>>>
export type GetUserExamExamIdResultQueryError = ErrorType<unknown>

export const useGetUserExamExamIdResult = <TData = Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError = ErrorType<unknown>>(
 examId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserExamExamIdResult>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserExamExamIdResultQueryOptions(examId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Submit an exam
 */
export const putUserExamExamIdSubmit = (
    examId: string,
    submitParticipantAnswers: BodyType<SubmitParticipantAnswers>,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/user/exam/${examId}/submit`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: submitParticipantAnswers
    },
      options);
    }
  


export const getPutUserExamExamIdSubmitMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdSubmit>>, TError,{examId: string;data: BodyType<SubmitParticipantAnswers>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdSubmit>>, TError,{examId: string;data: BodyType<SubmitParticipantAnswers>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserExamExamIdSubmit>>, {examId: string;data: BodyType<SubmitParticipantAnswers>}> = (props) => {
          const {examId,data} = props ?? {};

          return  putUserExamExamIdSubmit(examId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserExamExamIdSubmitMutationResult = NonNullable<Awaited<ReturnType<typeof putUserExamExamIdSubmit>>>
    export type PutUserExamExamIdSubmitMutationBody = BodyType<SubmitParticipantAnswers>
    export type PutUserExamExamIdSubmitMutationError = ErrorType<unknown>

    export const usePutUserExamExamIdSubmit = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserExamExamIdSubmit>>, TError,{examId: string;data: BodyType<SubmitParticipantAnswers>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUserExamExamIdSubmit>>,
        TError,
        {examId: string;data: BodyType<SubmitParticipantAnswers>},
        TContext
      > => {

      const mutationOptions = getPutUserExamExamIdSubmitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Retrieve a list of exams with optional filtering, sorting, and pagination.
 */
export const getUserExam = (
    params?: GetUserExamParams,
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/user/exam`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserExamQueryKey = (params?: GetUserExamParams,) => {
    return [`/user/exam`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserExamInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserExam>>>, TError = ErrorType<unknown>>(params?: GetUserExamParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExam>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserExamQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExam>>> = ({ signal }) => getUserExam(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExam>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserExamInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExam>>>
export type GetUserExamInfiniteQueryError = ErrorType<unknown>

export const useGetUserExamInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserExam>>>, TError = ErrorType<unknown>>(
 params?: GetUserExamParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserExam>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserExamInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserExamQueryOptions = <TData = Awaited<ReturnType<typeof getUserExam>>, TError = ErrorType<unknown>>(params?: GetUserExamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserExam>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserExamQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExam>>> = ({ signal }) => getUserExam(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserExam>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserExamQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExam>>>
export type GetUserExamQueryError = ErrorType<unknown>

export const useGetUserExam = <TData = Awaited<ReturnType<typeof getUserExam>>, TError = ErrorType<unknown>>(
 params?: GetUserExamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserExam>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserExamQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Retrieve a list of users with optional filtering, sorting, and pagination.
 */
export const getUser = (
    params?: GetUserParams,
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/user`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserQueryKey = (params?: GetUserParams,) => {
    return [`/user`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUser>>>, TError = ErrorType<unknown>>(params?: GetUserParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserInfiniteQueryError = ErrorType<unknown>

export const useGetUserInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUser>>>, TError = ErrorType<unknown>>(
 params?: GetUserParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserQueryOptions = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(params?: GetUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ErrorType<unknown>

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<unknown>>(
 params?: GetUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get My Info
 */
export const getUserMyInfo = (
    
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/user/myInfo`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserMyInfoQueryKey = () => {
    return [`/user/myInfo`] as const;
    }

    
export const getGetUserMyInfoInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUserMyInfo>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserMyInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserMyInfo>>> = ({ signal }) => getUserMyInfo(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserMyInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserMyInfoInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUserMyInfo>>>
export type GetUserMyInfoInfiniteQueryError = ErrorType<unknown>

export const useGetUserMyInfoInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUserMyInfo>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUserMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserMyInfoInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserMyInfoQueryOptions = <TData = Awaited<ReturnType<typeof getUserMyInfo>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserMyInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserMyInfo>>> = ({ signal }) => getUserMyInfo(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserMyInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserMyInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getUserMyInfo>>>
export type GetUserMyInfoQueryError = ErrorType<unknown>

export const useGetUserMyInfo = <TData = Awaited<ReturnType<typeof getUserMyInfo>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserMyInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update My Info
 */
export const putUserMyInfo = (
    updateUserInfo: BodyType<UpdateUserInfo>,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/user/myInfo`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserInfo
    },
      options);
    }
  


export const getPutUserMyInfoMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserMyInfo>>, TError,{data: BodyType<UpdateUserInfo>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putUserMyInfo>>, TError,{data: BodyType<UpdateUserInfo>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserMyInfo>>, {data: BodyType<UpdateUserInfo>}> = (props) => {
          const {data} = props ?? {};

          return  putUserMyInfo(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserMyInfoMutationResult = NonNullable<Awaited<ReturnType<typeof putUserMyInfo>>>
    export type PutUserMyInfoMutationBody = BodyType<UpdateUserInfo>
    export type PutUserMyInfoMutationError = ErrorType<unknown>

    export const usePutUserMyInfo = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserMyInfo>>, TError,{data: BodyType<UpdateUserInfo>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUserMyInfo>>,
        TError,
        {data: BodyType<UpdateUserInfo>},
        TContext
      > => {

      const mutationOptions = getPutUserMyInfoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    