// Core
import { FC } from 'react'

// App
import { cn } from '@src/lib/utils'

// Internal
import { LoadingProps } from './Loading.type'
import './Loading.css'

// Component
const Loading: FC<LoadingProps> = ({ className }) => {
  // Template
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={cn('animate-spin', className)}
    >
      <path d='M21 12a9 9 0 1 1-6.219-8.56'></path>
    </svg>
  )
}

export default Loading
