import { create, StateCreator } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

// Types
// States type
interface AuthStoreStatesType {
  isLoggedIn: boolean
  token: string | null
}

// Actions type
interface SetTokenParamsType {
  token: string | null
}

interface AuthStoreActionsType {
  setIsLoggedIn: (isLoggedIn: boolean) => void
  setToken: (params: SetTokenParamsType) => void
  removeToken: () => void
}

// Constants
const DEFAULT_AUTH_STORE_STATES: AuthStoreStatesType = {
  isLoggedIn: false,
  token: null
}

// Define store
const authStore: StateCreator<AuthStoreStatesType & AuthStoreActionsType> = (set) => ({
  // States
  ...DEFAULT_AUTH_STORE_STATES,

  // Methods
  setIsLoggedIn: (isLoggedIn: boolean) => set(() => ({ isLoggedIn })),
  setToken: ({ token }) =>
    set(() => ({
      token
    })),
  removeToken: () => {
    set(() => ({
      token: null
    }))
  }
})

const useAuthStore = create<AuthStoreStatesType & AuthStoreActionsType>()(
  devtools(
    persist(authStore, {
      name: 'auth-store',
      storage: createJSONStorage(() => sessionStorage)
    })
  )
)

export default useAuthStore
