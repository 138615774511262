// Core
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// App
import { useAuthStore } from '@src/stores'
import { useFetchUserInformation } from '@src/lib/hooks'
import { AppLoadingIcon, AppNavigationProgressBarProvider, AppThemeProvider } from '@src/components'

// Internal
import { useFetchWebsiteConfig } from './lib/hooks'

// Component
const App = () => {
  const {
    handleFetchUserInformation,
    isError: isFetchUserInformationError,
    isFetching: isFetchingUserInformation
  } = useFetchUserInformation()

  const { isError: isFetchWebsiteConfigError, isFetching: isFetchingWebsiteConfig } = useFetchWebsiteConfig()

  // Stores
  // Client
  const isLoggedIn = useAuthStore(({ isLoggedIn }) => isLoggedIn)

  // Effects
  useEffect(() => {
    console.log('[01/10/2024 10:38] Bug (Home page): Question count (Rebuild)')
  }, [])

  // Handle fetch user information on the first mount if user is logged in
  useEffect(() => {
    if (isLoggedIn) {
      handleFetchUserInformation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Template
  // Fetching
  if (isFetchingUserInformation || isFetchingWebsiteConfig) {
    return (
      <div className='py-6 flex justify-center items-center'>
        <AppLoadingIcon />
      </div>
    )
  }

  // Error
  if (isFetchUserInformationError || isFetchWebsiteConfigError) {
    return <div className='py-6 flex justify-center items-center'>Có lỗi xảy ra, vui lòng tải lại trang.</div>
  }

  // Finally
  return (
    <>
      <AppThemeProvider />
      <AppNavigationProgressBarProvider />
      <Outlet />
    </>
  )
}

export default App
