// Core
import { useEffect } from 'react'

// App
import { useWebsiteConfigStore } from '@src/stores'
import { useGetWebsiteConfig } from '@src/api/endpoints/website-config'
import { GetWebsiteConfigQueryType } from '@src/api/types/website-config'

// Use fetch user information
export const useFetchWebsiteConfig = () => {
  // Stores
  // Client
  const setWebsiteConfig = useWebsiteConfigStore((state) => state.setWebsiteConfig)

  // Server
  const getWebsiteConfigQuery = useGetWebsiteConfig<GetWebsiteConfigQueryType>()

  // Effects
  // Set store data after fetching website config
  useEffect(() => {
    if (getWebsiteConfigQuery.data) {
      const { name, logo, banner, theme } = getWebsiteConfigQuery.data.responseData
      setWebsiteConfig({
        name,
        logo: logo
          ? {
              id: logo._id,
              path: logo.file_path
            }
          : null,
        banner: banner ? { id: banner._id, path: banner.file_path } : null,
        theme
      })
    }
  }, [getWebsiteConfigQuery.data, setWebsiteConfig])

  return {
    isFetching: getWebsiteConfigQuery.isFetching,
    isError: getWebsiteConfigQuery.isError
  }
}
