// Core
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

// App
import { useWebsiteConfigStore } from '@src/stores'
import { CSS_VARIABLE_KEYS, THEMES } from '@src/lib/themes'

// Component
const ThemeProvider = () => {
  // Stores
  // Client
  const websiteConfigStore = useWebsiteConfigStore(
    useShallow(({ theme, previewTheme }) => ({
      theme,
      previewTheme
    }))
  )

  // Effects
  useEffect(() => {
    const appTheme = websiteConfigStore.previewTheme ?? websiteConfigStore.theme
    if (!appTheme || appTheme === 'default') {
      for (const cssVariableKey of CSS_VARIABLE_KEYS) {
        document.documentElement.style.removeProperty(cssVariableKey)
      }
      return
    }

    const selectedTheme = THEMES.find((theme) => theme.name === appTheme)
    if (selectedTheme) {
      for (const [key, value] of Object.entries(selectedTheme.cssVars.light)) {
        document.documentElement.style.setProperty(key, value)
      }
    }
  }, [websiteConfigStore])

  // Template
  return null
}

export default ThemeProvider
