/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * SHTT
 * Coded by Meu TEAM
 * OpenAPI spec version: 1.0.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Response,
  WebsiteConfigMutate
} from '../models'
import { customClient } from '../mutator/custom-client';
import type { ErrorType, BodyType } from '../mutator/custom-client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get Website Config
 */
export const getWebsiteConfig = (
    
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<Response>(
      {url: `/website-config`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWebsiteConfigQueryKey = () => {
    return [`/website-config`] as const;
    }

    
export const getGetWebsiteConfigInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getWebsiteConfig>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWebsiteConfig>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebsiteConfigQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebsiteConfig>>> = ({ signal }) => getWebsiteConfig(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWebsiteConfig>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWebsiteConfigInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getWebsiteConfig>>>
export type GetWebsiteConfigInfiniteQueryError = ErrorType<unknown>

export const useGetWebsiteConfigInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getWebsiteConfig>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getWebsiteConfig>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWebsiteConfigInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetWebsiteConfigQueryOptions = <TData = Awaited<ReturnType<typeof getWebsiteConfig>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebsiteConfig>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebsiteConfigQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebsiteConfig>>> = ({ signal }) => getWebsiteConfig(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebsiteConfig>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWebsiteConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getWebsiteConfig>>>
export type GetWebsiteConfigQueryError = ErrorType<unknown>

export const useGetWebsiteConfig = <TData = Awaited<ReturnType<typeof getWebsiteConfig>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebsiteConfig>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWebsiteConfigQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update Website Config
 */
export const putWebsiteConfig = (
    websiteConfigMutate: BodyType<WebsiteConfigMutate>,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<Response>(
      {url: `/website-config`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: websiteConfigMutate
    },
      options);
    }
  


export const getPutWebsiteConfigMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putWebsiteConfig>>, TError,{data: BodyType<WebsiteConfigMutate>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putWebsiteConfig>>, TError,{data: BodyType<WebsiteConfigMutate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putWebsiteConfig>>, {data: BodyType<WebsiteConfigMutate>}> = (props) => {
          const {data} = props ?? {};

          return  putWebsiteConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutWebsiteConfigMutationResult = NonNullable<Awaited<ReturnType<typeof putWebsiteConfig>>>
    export type PutWebsiteConfigMutationBody = BodyType<WebsiteConfigMutate>
    export type PutWebsiteConfigMutationError = ErrorType<unknown>

    export const usePutWebsiteConfig = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putWebsiteConfig>>, TError,{data: BodyType<WebsiteConfigMutate>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putWebsiteConfig>>,
        TError,
        {data: BodyType<WebsiteConfigMutate>},
        TContext
      > => {

      const mutationOptions = getPutWebsiteConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    