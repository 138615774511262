// Core
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

// App
import { getName, splitFullName } from '@src/lib/utils'
import { GetMyInfoQueryType } from '@src/api/types/user'
import { useAuthStore, useUserStore } from '@src/stores'
import { useGetUserMyInfo, getGetUserMyInfoQueryKey } from '@src/api/endpoints/user'

// Hook list
// Use query params
export const useQueryParams = () => {
  const [searchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])
  return searchParamsObject
}

// Use logout
export const useLogout = () => {
  // Stores
  // Client
  const userStore = useUserStore(
    useShallow(({ setIsFetched, removeUser }) => ({
      setIsFetched,
      removeUser
    }))
  )

  const authStore = useAuthStore(
    useShallow(({ setIsLoggedIn, removeToken }) => ({
      setIsLoggedIn,
      removeToken
    }))
  )

  // Methods
  // Logout
  const handleLogout = useCallback(() => {
    authStore.removeToken()
    authStore.setIsLoggedIn(false)

    userStore.removeUser()
    userStore.setIsFetched(false)
  }, [authStore, userStore])

  return {
    handleLogout
  }
}

// Use fetch user information
export const useFetchUserInformation = () => {
  const queryClient = useQueryClient()

  // Stores
  // Client
  const userStore = useUserStore(useShallow(({ setIsFetched, setUser }) => ({ setIsFetched, setUser })))

  // Server
  const getMyInfoQuery = useGetUserMyInfo<GetMyInfoQueryType>({
    query: {
      enabled: false
    }
  })

  // Methods
  // Handle fetch user information
  const handleFetchUserInformation = useCallback(async () => {
    // First refetch to mark as query is fetched, this refetching doesn't fetch api
    if (queryClient.getQueryData(getGetUserMyInfoQueryKey())) {
      await getMyInfoQuery.refetch()
    }

    // Fetching
    const { data } = await getMyInfoQuery.refetch()
    const userInformationData = data?.responseData
    if (!userInformationData) {
      return
    }

    const { lastName, middleName, firstName } = splitFullName(userInformationData.full_name)

    // Set user store
    userStore.setUser({
      id: userInformationData._id,
      lastName: lastName,
      middleName: middleName,
      firstName: firstName,
      email: userInformationData.email,
      phone: userInformationData.phone,
      district: userInformationData.unit.district,
      ward: userInformationData.unit.ward,
      isAdmin: userInformationData.is_admin
    })

    userStore.setIsFetched(true)
  }, [queryClient, getMyInfoQuery, userStore])

  return {
    isFetching: getMyInfoQuery.isFetching,
    isError: getMyInfoQuery.isError,
    handleFetchUserInformation
  }
}

// Use user full name
export const useUserFullName = () => {
  const userStore = useUserStore(
    useShallow(({ lastName, middleName, firstName }) => ({
      lastName,
      middleName,
      firstName
    }))
  )

  const userFullName = useMemo(() => {
    return getName({
      lastName: userStore.lastName ?? '',
      middleName: userStore.middleName ?? '',
      firstName: userStore.firstName ?? ''
    })
  }, [userStore])

  return userFullName
}

// Use skip initial effect
export const useSkipInitialEffect = (callback: CallableFunction, deps: unknown[]) => {
  // Ref
  const isInitialRenderRef = useRef(true)

  // Effect
  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false
      return
    }
    callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
