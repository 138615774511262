import { RouteObject } from 'react-router-dom'

// Routes
const userRoutes: RouteObject = {
  path: '/user',
  lazy: async () => {
    const { default: Component } = await import('@src/app/pages/user')
    return { element: <Component /> }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/home')
        return { element: <Component /> }
      }
    },
    {
      path: '/user/profile',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/profile')
        return { element: <Component /> }
      }
    },
    {
      path: '/user/exams/:id',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/exam')
        return { element: <Component /> }
      }
    },
    {
      path: '/user/exams/:id/result',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/exam-result')
        return { element: <Component /> }
      }
    }
  ]
}

export default userRoutes
