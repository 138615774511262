// Core
import { FC } from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

// Internal
import { PropsType } from './lib/types'
import { useNavigation } from 'react-router-dom'

TopBarProgress.config({
  barColors: {
    '0': 'hsl(221.2 83.2% 53.3%)',
    '1': 'hsl(221.2 83.2% 53.3%)'
  }
})

// Component
const NavigationProgressBarProvider: FC<PropsType> = () => {
  const navigation = useNavigation()

  // Template
  return navigation.state === 'loading' ? <TopBarProgress /> : null
}

export default NavigationProgressBarProvider
